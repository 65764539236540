import React from "react";
import { FaBars } from "react-icons/fa";

const Header = ({ handleNewSearch }) => {
  const alertFakeFeature = () => {
    alert("Product Finder is still in beta testing. For questions or feedback, please email chandler@suppleitco.com")
  }

  return (
    <header style={styles.header}>
      <div style={styles.leftSection}>
        <h1 style={styles.title}>Product Finder</h1>
      </div>
      <div style={styles.rightSection}>
        <button 
          style={styles.feedbackButton} 
          onClick={() => { window.open("https://forms.gle/B4gVakEZM87BF4sR9", "_blank") }}
        >
          Send Feedback
        </button>
        <button 
          style={styles.signInButton} 
          onClick={alertFakeFeature}
        >
          Sign In
        </button>
        <FaBars onClick={alertFakeFeature} style={styles.icon} />
      </div>
    </header>
  );
};

const styles = {
  header: {
    backgroundColor: "#4d8df5", // Updated blue color
    color: "white",
    padding: "20px",
    textAlign: "center",
    width: "100%",
    position: "fixed",
    top: "0",
    left: "0",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    zIndex: "1000",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxSizing: "border-box",
  },
  leftSection: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px",
  },
  title: {
    fontSize: "1.5rem",
    margin: "0",
  },
  rightSection: {
    display: "flex",
    alignItems: "center",
    paddingRight: "20px",
  },
  icon: {
    fontSize: "1.5rem",
    cursor: "pointer",
  },
  signInButton: {
    backgroundColor: "white",
    color: "#4d8df5", // Updated blue color
    border: "none",
    padding: "10px 20px",
    borderRadius: "4px",
    cursor: "pointer",
    marginRight: "20px",
  },
  feedbackButton: {
    backgroundColor: "white",
    color: "#4d8df5", // Updated blue color
    border: "1px solid #4d8df5", // Updated blue color
    padding: "10px 20px",
    borderRadius: "4px",
    cursor: "pointer",
    marginRight: "20px",
    transition: "background-color 0.3s, color 0.3s",
  },
  feedbackButtonHover: {
    backgroundColor: "#4d8df5", // Updated blue color
    color: "white",
  },
  newSearchButton: {
    backgroundColor: "white",
    color: "#4d8df5", // Updated blue color
    border: "none",
    padding: "10px 20px",
    borderRadius: "4px",
    cursor: "pointer",
    marginLeft: "10px",
    marginRight: "20px",
  },
};

export default Header;
