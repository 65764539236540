import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { FaSearch } from "react-icons/fa";
import debounce from "lodash.debounce";
import ReactMarkdown from "react-markdown";
import "./ProductForm.css";

const ProductForm = ({ setRecommendations, sessionId, onSearchComplete, input, setInput, setIsLoading, setStoredInput, description }) => {
  const [specificSite, setSpecificSite] = useState();
  const [numLinks, setNumLinks] = useState(6);
  const [numRecommendations, setNumRecommendations] = useState(6);
  const [isLoadingLocal, setIsLoadingLocal] = useState(false);
  const [searchInput, setSearchInput] = useState(input);
  const [suggestions, setSuggestions] = useState([]);
  const [isFetchingSuggestions, setIsFetchingSuggestions] = useState(false);
  const [response, setResponse] = useState("");
  const [isEscPressed, setIsEscPressed] = useState(false);
  const [searchMade, setSearchMade] = useState(false);
  const [isOutsideClicked, setIsOutsideClicked] = useState(false);
  const fetchSuggestionsRef = useRef(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [searchDuration, setSearchDuration] = useState(0);

  useEffect(() => {
    if (input) {
      setSearchInput(input);
      handleSearch(input);
    }
  }, [input]);

  const resetStates = () => {
    setSearchInput(""); // Clear the input field
    setSuggestions([]); // Remove the suggestions
    setIsOutsideClicked(false); // Reset the outside click state
  };

  const handleSearch = async (searchValue) => {
    setIsLoading(true);
    setIsLoadingLocal(true);
    setIsFormSubmitted(true);
    setIsEscPressed(false); // Reset the esc press state
    const startTime = Date.now();

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/search`, {
        params: {
          user_input: searchValue.trim(),
          num_links_to_scan: numLinks,
          num_recommendations: numRecommendations,
          specific_site: specificSite,
          session_id: sessionId, // Pass session ID to maintain context
        },
      });
      const endTime = Date.now();
      setSearchDuration(((endTime - startTime) / 1000).toFixed(2));
      setRecommendations(response.data.recommendations);
      setResponse(response.data.description); // Set the description from the search API response
      onSearchComplete(); // Hide the form after search
      resetStates(); // Reset states after search
      setSearchMade(true); // Indicate that a search has been made
      setStoredInput(searchValue); // Store the input value
    } catch (error) {
      console.error("Error fetching product recommendations:", error);
    } finally {
      setIsLoading(false);
      setIsLoadingLocal(false);
      setIsFormSubmitted(false);
      setIsEscPressed(false); // Reset the esc press state after search
    }
  };

  const fetchSuggestions = async (value) => {
    if (isFetchingSuggestions || isEscPressed) return;
    setIsFetchingSuggestions(true);

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/suggestions`, {
        params: {
          partial_input: value,
          num_suggestions: 5,
        },
      });
      if (!isFormSubmitted) {
        setSuggestions(response.data.suggestions || []); // Ensure suggestions is an array
      }
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setSuggestions([]); // Set suggestions to an empty array on error
    } finally {
      setIsFetchingSuggestions(false);
    }
  };

  const debouncedFetchSuggestions = useRef(debounce(fetchSuggestions, 300)).current;

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);

    // Reset the outside click state when user types something
    setIsOutsideClicked(false);

    if (value.length === 0) {
      setIsEscPressed(false); // Reset the esc press state when input is cleared
    }

    if (value.length > 0 && !isEscPressed) {
      debouncedFetchSuggestions(value);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchInput(suggestion);
    setSuggestions([]);
    handleSearch(suggestion); // Confirm that the suggestion will be used as the product to search for
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSearch(searchInput);
    resetStates(); // Reset states after form submission
  };

  const handleOutsideClick = (e) => {
    if (!e.target.closest('.inputGroup')) {
      setSuggestions([]);
      setIsOutsideClicked(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      setSuggestions([]);
      setIsEscPressed(true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleShowMeClick = () => {
    handleSearch("Show me products based on the last question I asked.");
  };

  const handleSiteButtonClick = (site) => {
    setSpecificSite(site);
  };

  return (
    <form onSubmit={handleFormSubmit} className="form">
      <div className="inputGroup">
        <label className="label">
          <FaSearch className="icon" /> Find a Product:
        </label>
        <p className="description">Enter the product you're looking for or ask a question.</p>
        <input
          type="text"
          value={searchInput}
          onChange={handleInputChange}
          className="input"
          required
        />
        {searchInput.length > 0 && suggestions.length > 0 && !isLoadingLocal && !isEscPressed && (
          <ul className="suggestionsList">
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                className="suggestionItem"
              >
                {suggestion}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="buttonGroup">
        <button type="submit" className="button" style={{ marginRight: "5px" }} disabled={isLoadingLocal}>Search</button>
      </div>
      <div className="siteButtons">
        <div>Search Vendor:</div>
        <button type="button" onClick={() => handleSiteButtonClick("")} className={`button ${specificSite === "" ? "active" : ""}`}>All</button>
        <button type="button" onClick={() => handleSiteButtonClick("grainger.com")} className={`button ${specificSite === "grainger.com" ? "active" : ""}`}>Grainger</button>
        <button type="button" onClick={() => handleSiteButtonClick("mscdirect.com")} className={`button ${specificSite === "mscdirect.com" ? "active" : ""}`}>MSC Direct</button>
        <button type="button" onClick={() => handleSiteButtonClick("amazon.com")} className={`button ${specificSite === "amazon.com" ? "active" : ""}`}>Amazon</button>
        <button type="button" onClick={() => handleSiteButtonClick("homedepot.com")} className={`button ${specificSite === "homedepot.com" ? "active" : ""}`}>Home Depot</button>
      </div>
      {description && (
        <div className="response-container">
          <div className="search-duration">Thought for {searchDuration} seconds...</div>
          <ReactMarkdown>{description}</ReactMarkdown>
          {/* <button onClick={handleShowMeClick} className="button" style={{ marginBottom: "15px" }}>Show me</button> */}
        </div>
      )}
    </form>
  );
};

export default ProductForm;
