import React, { useState, useEffect } from "react";
import ProductForm from "./components/ProductForm/ProductForm";
import ProductList from "./components/ProductList/ProductList";
import Header from "./components/Header";
import ExampleSearches from "./components/ExampleSearches";
import Spinner from "./components/Spinner/Spinner";
import { v4 as uuidv4 } from "uuid";
import ReactGA from 'react-ga';

function App() {
  const [recommendations, setRecommendations] = useState([]);
  const [sessionId, setSessionId] = useState("");
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [initialQueryDone, setInitialQueryDone] = useState(false);
  const [storedInput, setStoredInput] = useState("");
  const [description, setDescription] = useState(""); // New state for description

  // Function to generate a new session ID
  const generateNewSessionId = () => {
    const newSessionId = uuidv4();
    const timestamp = Date.now();
    localStorage.setItem("sessionId", newSessionId);
    localStorage.setItem("sessionIdTimestamp", timestamp);
    setSessionId(newSessionId);
  };

  useEffect(() => {
    // Initialize Google Analytics and track page view
    ReactGA.initialize('G-5QCLJ38RT2');
    ReactGA.pageview(window.location.pathname + window.location.search);

    // Check if session ID exists in localStorage and is not older than a day
    const storedSessionId = localStorage.getItem("sessionId");
    const storedTimestamp = localStorage.getItem("sessionIdTimestamp");
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

    if (storedSessionId && storedTimestamp && (Date.now() - storedTimestamp < oneDayInMilliseconds)) {
      setSessionId(storedSessionId);
    } else {
      generateNewSessionId();
    }
  }, []);

  useEffect(() => {
    const createBrowsers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/create_browsers?session_id=${sessionId}&num_browsers=6`);
        const data = await response.json();
        console.log(`Created browsers for session: ${data.session_id}`);
      } catch (error) {
        console.error("Error creating browsers:", error);
      }
    };

    if (sessionId) {
      createBrowsers();
    }
  }, [sessionId]);

  const handleNewSearch = () => {
    // Reset the state for a new search
    setRecommendations([]);
    setSearchCompleted(false);
    setInitialQueryDone(false);

    // Generate a new session ID for the new search
    generateNewSessionId();

    // Track new search event
    ReactGA.event({
      category: 'User',
      action: 'Started New Search'
    });
  };

  const handleSearchComplete = () => {
    // Mark the search as completed
    setSearchCompleted(true);
    setStoredInput(input); // Store the input before clearing it
    setInitialQueryDone(true);

    // Track search complete event
    ReactGA.event({
      category: 'User',
      action: 'Search Completed'
    });
  };

  return (
    <div className="App" style={styles.app}>
      <Header handleNewSearch={handleNewSearch} />
      <Spinner isLoading={isLoading} />
      <div style={styles.content}>
        <ProductForm
          setRecommendations={setRecommendations}
          sessionId={sessionId}
          onSearchComplete={handleSearchComplete}
          input={input}
          setInput={setInput}
          setIsLoading={setIsLoading}
          handleNewSearch={handleNewSearch}
          setStoredInput={setStoredInput} // Pass setStoredInput to ProductForm
          description={description}
        />
        {recommendations.length > 0 && (
          <ProductList 
            recommendations={recommendations} 
            sessionId={sessionId} 
            userQuery={storedInput} 
            setDescription={setDescription} // Pass setDescription to ProductList
          />
        )}
      </div>
    </div>
  );
}

const styles = {
  app: {
    fontFamily: "Arial, sans-serif",
    padding: "20px",
    backgroundColor: "#f4f4f9",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center", // Center contents vertically
    boxSizing: "border-box",
  },
  header: {
    marginLeft: "20px",
    color: "#333",
  },
  newSearchButton: {
    marginTop: "20px",
    padding: "10px 20px",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  content: {
    marginTop: "80px", // Ensure content is not hidden under the header
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  description: {
    marginTop: "20px",
    padding: "10px",
    backgroundColor: "#fff",
    borderRadius: "4px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    width: "80%",
    textAlign: "center",
  },
  emptyState: {
    marginTop: "20px",
    padding: "10px",
    backgroundColor: "#fff",
    borderRadius: "4px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    width: "80%",
    textAlign: "center",
    color: "#999",
    fontSize: "1rem",
  },
};

export default App;
